* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.hero-container {
  background-image: url("./Assets/hero.jpg");
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative; /* Add position relative */
}

.overlay {
  width: 100%;
  height: 100vh;
  z-index: 2;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom: 6px solid var(--theme);
}

.hero-content button {
  width: 150px;
  height: 50px;
  border: none;
  border-radius: 10px;
  background-color: #f64668;
  color: #fff;
  font-size: 18px;
}

.logo {
  position: absolute;
  z-index: 3;
  top: 7px;
  left: 7px;
  width: 200px;
  height: auto;
  border-radius: 10px;
}

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}




.hero-content button {
  cursor: pointer; /* Add cursor pointer for better UX */
}

.book-btn{
  background-color: #f64668 !important;
  margin-right: 10px;
}
.book-btn:hover{
  background-color: #f64668 !important;
}


/* HIDDEN */
.hide-hero-content {
  display: none;
}

.plus-button{
  background-color: #f64668 !important;
  color: #fff;
  border: none;
  margin-left: 10px;
}

.plus-button:hover{
  color: black !important;
  border: 1px solid #f64668 !important;
}

/* .plus-icon{
  color: #fff;
} */



@media screen and (max-width: 426px) {
  .hero-content {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 40%;
    padding: 10px;
    color: #fff;
    text-align: center;
  }

  .hero-content h1 {
    font-size: 25px;
    margin-bottom: 20px;
  }

  .form-container {
    position: absolute;
    z-index: 3;
    width: 300px;
    height: 90%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.15); /* Use rgba to set opacity */
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add box shadow */
    transition: all 0.3s ease; /* Add transition for smooth animation */
    transform: translateY(5%); /* Initially move the form container out of the viewport */
    backdrop-filter: blur(10px); /* Apply backdrop filter for glassmorphism effect */
  }

}

@media screen and (max-width: 769px) {
  .hero-content {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 40%;
    padding: 10px;
    color: #fff;
    text-align: center;
  }

  .hero-content h1 {
    font-size: 25px;
    margin-bottom: 20px;
  }

  .form-container {
    position: absolute;
    z-index: 3;
    width: 400px;
    height: 90%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.15); /* Use rgba to set opacity */
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add box shadow */
    transition: all 0.3s ease; /* Add transition for smooth animation */
    transform: translateY(5%); /* Initially move the form container out of the viewport */
    backdrop-filter: blur(10px); /* Apply backdrop filter for glassmorphism effect */
  }
}
